@font-face {
  font-family: Barlow;
  src: url(fonts/Barlow-Regular.ttf);
}

@font-face {
  font-family: Barlow;
  src: url(fonts/Barlow-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Engagement;
  src: url(fonts/Engagement-Regular.ttf);
}

html, body {
  text-align: center;
  font-family: Barlow, Helvetica, Arial, sans-serif;
}

main {
  text-align: left;
  padding-top: 2rem;

  h1 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.75rem;
  }

  h2, h3 {
    font-size: 1rem;
    font-weight: bold;
    margin: 2rem 0 1rem;
  }
}

header {
  position: fixed;
}

footer {
  text-align: left;
  background-color: #BFB7A2;
  color: white;
  padding: 0.5rem 0;

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .copyright {
    text-align: center;
    margin-bottom: 1rem;
  }

}

.logo {
  h1 {
    font-family: Engagement;
    font-size: 4rem;
    font-weight: normal;
    margin: 0;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: normal;
    margin: 0 0 0;
  }
}

.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  width: 100%;
  height: 50vh;

  background-image: url('../img/jokers-and-queens-01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;

  .logo {
    color: white;
    transform: translateY(-1.75rem);

    &:hover {
      text-decoration: none;
    }
  }

  nav {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(20, 20, 20, 0.6);
  }

  ol {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    list-style-type: none;
    margin: 0;
    padding: 0 0 0 0.5rem;
  }

  li {
    padding: 1rem 0.5rem;
  }

  a {
    font-size: 1.25rem;
    color: white;
    text-decoration: none;

    &.active {
      font-weight: bold;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .icon {
    padding: 0 1rem;

    a {
      color: rgba(255, 255, 255, 0.85);

      &:hover {
        color: white;
      }
    }

  }

  .fa-2x {
    font-size: 2rem;
  }

  .divider {
    padding: 0 1rem;
    font-size: 2rem;

    &::after {
      content: ' ';
      height: 100%;
      border-left: 1px solid rgba(255, 255, 255, 0.35);
    }
  }

  .logo {
    position: relative;
    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.75));

    .shadow {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
    }
  }
}

.about {
  //text-align: center;
}

.team {
  outline: 10px solid white;
  box-shadow: 0 0 1rem #000;
  transform: rotate(2deg);
  width: 100%;
  height: auto;
}

.instruments {
  padding-top: 5rem;
  padding-bottom: 1rem;

  img {
    display: inline-block;
    margin-bottom: 2rem;
    width: 100%;
    height: auto;
  }
}

.jq-badge {
  font-family: Engagement;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.4);
  display: inline-block;
  position: relative;
  transform: translateY(-3.6rem);
  margin-bottom: -2rem;
  background-color: white;
  padding: 1rem;
}

.logo-footer {
  margin: 5rem 0 5rem;
}

footer {
  ol {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-left: 1.5rem;
  }

  li:first-child {
    margin-left: 0;
  }
}

@import "responsive";
