// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .hero {
    height: 80vh;

    .logo {
      transform: scale(1.15) translateY(-3rem);
    }

    ol {
      justify-content: center;
    }

    li {
      padding: 1rem 1rem;
    }
  }
  .about {
    text-align: left;
    padding-top: 1rem;
  }
  main {
    padding-top: 5rem;
  }
  .instruments {
    img {
      width: auto;
    }
  }
  footer {
    .copyright {
      text-align: left;
      margin-bottom: 0;
    }

    ol {
      justify-content: end;
    }
  }
}
